/* global $ */
class App {
    constructor() {
        this.menuMoving = false;
        this.initSearchfilter();
        this.initFancybox();
        this.disableLogger();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms();
        this.initFontAwesome();
        this.initStickyMenu();
        this.initModal();
        this.initCarousel();
        this.initMatrixBlock();
        this.initSelect2();
        $('body').css('opacity', 1);
        console.log('App initialised');
    }

    initSelect2() {
        if ($('.form-select').length) {
            import(/* webpackChunkName: "select2" */ './components/select2').then(() =>{
                $('.form-select').each(function () {
                    $(this).select2({
                        width: 'style',
                        minimumResultsForSearch: -1,
                        dropdownPosition: 'below',
                    });
                });
                $('#help-your-employees-field-visitorConsent-input').select2({
                    dropdownParent: $('#helpYourEmployeesForm .modal-body'),
                    width: 'style',
                    minimumResultsForSearch: -1,
                    dropdownPosition: 'below',
                });
            });
        }
    }

    disableLogger() {
        if (window.Globals.env == 'production') {
            console.log = function () { };
        }
    }

    initFontAwesome() {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone, .form-select, .category-select').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms() {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form.js-validate'), (i, elem) => {
                    new chunk.Form(elem.id);
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"), (i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    initToasts(elems) {
        import(/* webpackChunkName: "bootstrap" */ 'bootstrap').then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initTooltips(elems) {
        import(/* webpackChunkName: "bootstrap" */ 'bootstrap').then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    initModal() {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "modal" */ './components/modal').then(modal => {
                new modal.Modal;
            });
        }
    }

    initCarousel() {
        import(/* webpackChunkName: "carousel" */ './components/carousel').then(carousel => {
            new carousel.Carousel;
        });
    }

    initFancybox() {
        if ($('.fancybox-image-gallery').length) {
            import(/* webpackChunkName: "fancybox" */ './components/fancybox');
        }
    }

    initSearchfilter() {
        if ($('.all-blogs').length || $('.all-case-studies').length || $('.all-coaches').length) {
            import(/* webpackChunkName: "searchfilter" */ './components/searchfilter').then(filter => {
                new filter.Searchfilter;
            });
        }
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 100) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });
    }

    initMatrixBlock() {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "matrixblock" */ './components/matrixblock.js').then(matrixblock => {
                new matrixblock.Matrixblock;
            });
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    multiSelect() {
        $("#locations").select2({
            closeOnSelect: false,
            placeholder: "Locations",
            allowClear: true,
            dropdownPosition: 'below',
            dropdownParent: $('#location-multi-select-container'),
        });
        $("#languages").select2({
            closeOnSelect: false,
            placeholder: "Languages",
            allowClear: true,
            dropdownPosition: 'below',
            dropdownParent: $('#language-multi-select-container'),
        });
        $("#specialities").select2({
            closeOnSelect: false,
            placeholder: "Specialities",
            allowClear: true,
            dropdownPosition: 'below',
            dropdownParent: $('#speciality-multi-select-container'),
        });
    }
}

export default App;